import styled, { css } from 'styled-components'

import { Container, Tag, Text } from 'common/UI'
import { ClinicSectionHeaderStoryblok } from 'common/types'
import { textByLine } from 'common/utils/content'
import { addAlphaToColor } from 'common/utils/style'
import { CMSLink } from 'modules/shared'

import Arrow from './assets/sectionHeaderArrow.svg'

type Props = {
  block: ClinicSectionHeaderStoryblok
}

export const ClinicSectionHeader = ({
  block,
  ...props
}: Props): JSX.Element => {
  const { variant, line, tag, title, description, cta } = block

  return (
    <SectionWrapper hasLine={line} {...props}>
      {line && <Flair />}

      <Container>
        <Wrapper variant={variant}>
          {tag && <StyledTag variant={variant} label={tag} />}

          <InnerWrapper variant={variant}>
            {title && (
              <WrapperTitle variant={variant}>
                <Text
                  variant={variant === 'default' ? 'title/large' : 'title'}
                  as="h3"
                  css={{ marginBottom: '1rem' }}
                >
                  {title}
                </Text>
              </WrapperTitle>
            )}

            {variant === 'arrow' && <StyledArrow />}

            {description && (
              <WrapperDescription variant={variant}>
                {textByLine(description, (part) => (
                  <>
                    <TextLine
                      as="p"
                      variant={
                        variant === 'default' || variant === 'center'
                          ? 'twenty'
                          : 'title'
                      }
                    >
                      {part}
                    </TextLine>
                  </>
                ))}
              </WrapperDescription>
            )}

            {cta?.[0] && cta[0].link && (
              <Cta href={cta[0].link} variant="ghost" rightIcon="arrow-right">
                {cta[0].label}
              </Cta>
            )}
          </InnerWrapper>
        </Wrapper>
      </Container>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.section<{ hasLine?: boolean }>`
  --margin-top: 1rem;

  position: relative;

  ${({ hasLine }) =>
    hasLine &&
    css`
      margin-top: var(--margin-top);
    `}
`

const Flair = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.low};
  width: 100%;
  height: 1rem;
  top: -1rem;

  background-image: url('/assets/flair.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

const Wrapper = styled.div<{
  variant: ClinicSectionHeaderStoryblok['variant']
}>`
  display: flex;
  flex-direction: column;

  align-items: ${({ variant }) => (variant === 'center' ? 'center' : 'normal')};

  text-align: ${({ variant }) => (variant === 'center' ? 'center' : 'start')};

  padding: ${({ variant }) =>
    variant === 'arrow'
      ? '3.75rem 0rem 2rem 0rem'
      : '2.5rem 0rem 1.25rem 0rem'};

  ${({ theme }) => theme.media.lg} {
    padding: ${({ variant }) => {
      if (variant === 'default') return '3.75rem 0rem'
      if (variant === 'center') return '5rem 0rem 2.5rem 0rem'
      return '7.5rem 0rem 3.75rem 0rem'
    }};
  }
`

const InnerWrapper = styled.div<{
  variant: ClinicSectionHeaderStoryblok['variant']
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ variant }) =>
    variant === 'center' || variant === 'arrow' ? 'center' : 'start'};

  ${({ theme }) => theme.media.lg} {
    flex-direction: ${({ variant }) =>
      variant === 'center' ? 'column' : 'row'};

    justify-content: ${({ variant }) =>
      variant === 'default' ? 'space-between' : 'unset'};
  }
`

const WrapperTitle = styled.div<{
  variant: ClinicSectionHeaderStoryblok['variant']
}>`
  ${({ theme }) => theme.media.lg} {
    margin-right: ${({ variant }) => (variant === 'center' ? '0' : '2.5rem')};
    max-width: ${({ variant }) =>
      variant === 'center' ? '36.438rem' : '33.125rem'};
  }
`

const WrapperDescription = styled.div<{
  variant: ClinicSectionHeaderStoryblok['variant']
}>`
  margin-top: ${({ variant }) => (variant === 'arrow' ? 'auto' : 'unset')};
  margin-left: ${({ variant }) => (variant === 'arrow' ? 'auto' : 'unset')};

  color: ${({ theme, variant }) =>
    variant === 'arrow'
      ? theme.colors.background.secondaryOrange
      : theme.colors.foreground.default};

  ${({ theme }) => theme.media.lg} {
    max-width: 39.375rem;
  }
`

const StyledTag = styled(Tag)<{
  variant: ClinicSectionHeaderStoryblok['variant']
}>`
  margin-bottom: 1rem;

  ${({ theme }) => theme.media.lg} {
    margin-bottom: ${({ variant }) =>
      variant === 'center' ? '1rem' : '1.25rem'};
  }
`

const StyledArrow = styled(Arrow)`
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 40)};
  margin-left: auto;
  transform: scale(0.4);
  margin-top: -2.5rem;
  margin-right: -1.875rem;

  ${({ theme }) => theme.media.md} {
    min-width: 10rem;
  }

  ${({ theme }) => theme.media.lg} {
    margin-left: unset;
    transform: none;
    margin-top: unset;
    margin-right: 2.5rem;
  }
`

const Cta = styled(CMSLink)`
  max-width: fit-content;
  margin-top: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    margin-top: 1.5rem;
  }
`

const TextLine = styled(Text)`
  :not(:first-child) {
    margin-top: 0.625rem;
  }
`
